import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav } from 'react-bootstrap';
import MenuIcon from './MenuIcon'
function PageMenu({profile}) {
  return (
    <Navbar>
      <Nav>
        {profile.menu.map((menu_item) => (
          <NavLink key={menu_item.id} to={menu_item.slug} className="nav-link" >
            <span className={menu_item.slug}>
              <MenuIcon menu_item={menu_item.slug} />
            </span>
          </NavLink>
        ))}
      </Nav>
    </Navbar>
  );
}

export default PageMenu;