import React from "react";
import { SocialIcon } from 'react-social-icons';
import { BsApple } from 'react-icons/bs'

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function AppleLink({link}) {
  return (        
    <a href={link.url} target="_blank" rel="noreferrer" className="list-group-item">
      <BsApple className="apple-icon smright grow float-left" />
      Listen on Apple Music
    </a>
  );
}

function MusicLink({link}) {
  if (link.kind === "applemusic") {
    return <AppleLink link={link} />;
  } else {
    return (        
      <a href={link.url} target="_blank" rel="noreferrer" className="list-group-item">
        <SocialIcon bgColor={link.bg_color} network={link.kind} className="smright grow float-left" />
        Listen on {toTitleCase(link.kind)}
      </a>
    );
  }
}

export default MusicLink;