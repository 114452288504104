import React from "react";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import YoutubeBackground from 'react-youtube-background';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../GoogleAnalytics'
import {
  Header,
  Home,
  Footer,
  PageTemplate,
  MailingList,
  Videos, Store, Music, About, Calendar  
} from "../components";

function Page({page, profile}) {
    if (page.slug === "video" || page.slug === "videos") {
      return <PageTemplate page={ <Videos videos={page.videos} profile={profile} /> } />;
    } else if (page.slug === "about") {
      return <PageTemplate page={ <About page={page} profile={profile} /> } />;
    } else if (page.slug === "mailing_list") {
      return <PageTemplate page={ <MailingList page={page} profile={profile} /> } />;
    } else if (page.slug === "store") {
      return <PageTemplate page={ <Store page={page} profile={profile} /> } />;
    } else if (page.slug === "music") {
      return <PageTemplate page={ <Music page={page} profile={profile} /> } />;
    } else if (page.slug === "calendar") {
      return <PageTemplate page={ <Calendar page={page} profile={profile} /> } />;
    }
  }

function ClassicLayout({ profile }) {
    return (
    <>
        <GoogleAnalytics profile={profile} />
        <YoutubeBackground
        videoId={profile.bg_video_id}     /* default -> null */
        aspectRatio="16:9"            /* default -> "16:9" */
        className="VideoBg"           /* default -> null */
        />
        <Helmet>
            <title>{profile.name} | Official Website</title>
            <meta name="description" content={profile.meta_description} />
            <link rel="icon" href={profile.image} sizes="16x16" />
            <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
            integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
            crossorigin="anonymous"
            />
        </Helmet>
        <Router>
          <Header profile={profile} />
          <Routes>
            <Route path="/" element={<Home profile={profile} />} />
              {profile.pages.map((page) => ( 
                <Route key={page.id} path={page.slug} element={<Page page={page} profile={profile} />} />
              ))}
            <Route path="/mailing_list" element={<PageTemplate page={<MailingList />} />} />
          </Routes>
        </Router>
        <Footer />
    </>
    )
}

export default ClassicLayout;