import React from "react";

class Calendar extends React.Component {
  render() {
    const profile = this.props.profile;
    const events  = profile.events;    
    if (!events.length) {
      return (
        <div class="panel">
        We don't have any calendar events coming up.
        </div>
      );
    } else {
      return (
        <div className="events">
          {events.map((event) => (
            <div className="row panel">
              <div className="col-lg-3">
                <center>
                  <h3 className="mtop">{ event.date }</h3>
                </center>
              </div>
              <div className="col-lg-9">
                <b>{event.title}</b>
                <p>
                  {event.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      );
    }        
  }
}

export default Calendar;