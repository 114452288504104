import React from "react";

class ContentSections extends React.Component {
  render() {
    return (
      <>
        {this.props.content_sections.map((content_section) => (            
            <div className="row align-items-center my-5" key={content_section.id}>
              <div className="col-lg-1"></div>
              <div className="col-lg-3">
                <h4 dangerouslySetInnerHTML={{__html:content_section.title}}></h4>
              </div>
              <div className="col-lg-8">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div dangerouslySetInnerHTML={{__html:content_section.body}}></div>
                  </div>
                </div>
              </div>
            </div>
          ))} 
        </>
    );
  }
}

export default ContentSections;