import React from "react";


class Store extends React.Component {
  render() {      
    const store = this.props.profile.store;
    return (                  
      <div className="row justify-content-center">
        {store.products.reverse().map((product) => (
          <div className="col-lg-4 mbottom product" key={product.id}>
            <div className="panel">
              <img src={product.cover_art} className="img-thumbnail shadow mbottom" alt={product.name} />
              <h5>{product.name}</h5>
              <p>{product.description}</p>
              <h3>{product.price}</h3>
              <br/>
              <a href={product.download_link} className="btn btn-secondary btn-lg btn-block" download>Download</a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Store;