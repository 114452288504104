import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';

function MailingListForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState(""); 

    const onNameChange = e => setName(e.target.value);
    const onEmailChange = e => setEmail(e.target.value);

    const post_url = process.env.REACT_APP_API_URL + "/api/contacts/?host=" + window.location.hostname;    

    const handleSubmit = e => {
      e.preventDefault();
  
      const data = { name, email };
      const requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      };
      fetch(post_url, requestOptions)
        .then(response => response.json())
        .then(res => alert("Thank you! Your email is submitted."))
        .then(response => {
            window.location.href = "/";
        });
    };
  
    return (
        <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control value={name} type="text" placeholder="Name" onChange={onNameChange} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control value={email} type="email" placeholder="Enter email" onChange={onEmailChange} />
            <Form.Text className="text-muted">
                We'll never share your email with anyone else.
            </Form.Text>
        </Form.Group>
        <Button variant="secondary" type="submit" className="btn-block btn-lg" >
        Submit
        </Button>
    </Form>

    );
  }
  
  export default MailingListForm;