import React from "react";
import { FaCameraRetro } from 'react-icons/fa';
import { BsFillChatTextFill } from 'react-icons/bs';
import { BsCartFill } from 'react-icons/bs';
import { BsFillCalendarWeekFill } from 'react-icons/bs';
import { AiFillVideoCamera } from 'react-icons/ai';
import { GiAudioCassette } from 'react-icons/gi';
import { GiSecretBook } from 'react-icons/gi';
function MenuIcon({menu_item}) {
  if (menu_item === "videos" || menu_item === "video") {
    return (<AiFillVideoCamera />);
  } else if (menu_item === "calendar") {
    return (<BsFillCalendarWeekFill />);
  } else if (menu_item === "") {
    return <BsFillChatTextFill />;
  } else if (menu_item === "about") {
    return <GiSecretBook />;
  } else if (menu_item === "pictures") {
    return (<FaCameraRetro />);
  } else if (menu_item === "store") {
    return (<BsCartFill />);
  } else if (menu_item === "music") {
    return (<GiAudioCassette />);
  } else {
    return (<div>{menu_item}</div>);
  }
}
  
export default MenuIcon;