import React from "react";
import ReactPlayer from 'react-player';

class Video extends React.Component {
  render() {
    return (
      <div className='player-wrapper'>
      <ReactPlayer 
        className="react-player"
        url={this.props.video.url} 
        width="100%"
        height="100%"
      />
      </div>
    );
  }
}
export default Video;