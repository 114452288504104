import React from "react";
import { SocialIcon } from 'react-social-icons';
import { Navbar, Nav } from 'react-bootstrap';

function Socials({profile}) {
  return (
    <Navbar>
        <Nav className="mr-auto">          
        {profile.socials.map((social) => (
          <Nav.Item key={social.id}>
            <SocialIcon url={social.url} bgColor={social.bg_color} className="smright grow" />
          </Nav.Item>
        ))}
        </Nav>
    </Navbar>
  );
}

export default Socials;