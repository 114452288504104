import React from "react";
import { Button } from 'react-bootstrap';
import Socials from "./Socials";
class Home extends React.Component {
  render() { 
    return (
      <div className="home">
        <div className="container content align-top">
          <div className="row align-items-center align-top">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
              <a href="/store">
                <img src={this.props.profile.image} className="img-thumbnail hide-mobile shadow grow" alt={this.props.profile.name} />
              </a>
            </div>
            <div className="col-lg-8">
              <h1 className="font-weight-light">{this.props.profile.title}</h1>
              <div className="panel panel-default">
                <div className="panel-body">
                  <div dangerouslySetInnerHTML={{__html:this.props.profile.welcome}}></div>
                </div>
                <hr/>
                <div className="panel-body">
                  <p>Get Connected:</p>
                  <Socials profile={this.props.profile} />
                  <br/>
                  <Button href="/mailing_list" variant="secondary">Join the Mailing List</Button>
                </div>
              </div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;