import React from "react";
import Logo from "./Logo";
import PagesMenu from "./PagesMenu";
import { Navbar, Container } from 'react-bootstrap';

function Header({profile}) {
  return (
    <Container id="header">
      <Navbar expand="lg" variant="dark">
        <Logo profile={profile} />
        <PagesMenu profile={profile} />
      </Navbar>
    </Container>
  );
}

export default Header;