import React from "react";
import Video from './Video';
import Slider from "react-slick";
import "./css/slick.css"; 
import "./css/slick-theme.css";
import "./css/ajax-loader.gif";
import "./css/Videos.css";
import "./css/tv.png";
import { BsCaretLeftFill, BsCaretRightFill, BsFillCameraReelsFill } from "react-icons/bs";


class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }  
  render() {      
    return (
    <>
      <div className="row video-remote">
        <div className="col-lg-12">
          <div style={{ textAlign: "center" }}>
            <button className="btn btn-secondary prev-video" onClick={this.previous}>
             <BsCaretLeftFill />
             <BsFillCameraReelsFill />
            </button>
            <button className="btn btn-secondary next-video" onClick={this.next}>
              <BsFillCameraReelsFill />            
              <BsCaretRightFill />              
            </button>
          </div>            
        </div>
    </div>   
      <div className="row">
        <div className="col-lg-12">
          <div className="tv">  
            <Slider ref={c => (this.slider = c)}>
                {this.props.videos.reverse().map((video) => (
                  <h3 className="tv-video" key={video.id}>
                    <Video video={video} />
                  </h3>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
    );
  }
}

export default Videos;