import React from "react";
import ContentSections from './ContentSections'

class About extends React.Component {
  render() {      
    return (                  
      <ContentSections content_sections={this.props.page.content_sections} />        
    );
  }
}

export default About;