import React from "react";
import { Navbar } from 'react-bootstrap';

function Logo({profile}) {
  if (profile.logo) {
    return (
      <Navbar.Brand href="/">
        <img src={profile.logo} id="logo" className="grow" alt={profile.name} />
      </Navbar.Brand>
    );
  } else {
    return (
      <Navbar.Brand href="/">
        <h1>{profile.name}</h1>
      </Navbar.Brand>
    );   
  }
}

export default Logo;