import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { ClassicLayout } from "./layouts";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      profile: []
    };  
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + "/api/profile/?host=" + window.location.hostname)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            profile: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }    
  render() {
    const { error, isLoaded, profile } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return(
        <ClassicLayout profile={profile} />
      )
    }
  }
}

ReactDOM.render(  
 <App />,
  document.getElementById("root")
);

serviceWorker.unregister();