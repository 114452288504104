import React from "react";
import MailingListForm from "./MailingListForm";

import { Row, Col, Container } from 'react-bootstrap';

class MailingList extends React.Component {      
    render() {                               
      return (
        <Container>
          <Row className="justify-content-center">
            <Col className="col-sm-7">
                <div className="panel panel-default">
                  <div className="panel-body">
                    <h3>Join the Mailing List</h3>
                    <MailingListForm />
                  </div>           
                </div>
            </Col>
          </Row>
        </Container>
      );      
    }
  }

  export default MailingList;
