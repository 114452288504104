import React from "react";
import MusicLink from "./MusicLink";
import { Row, Col } from 'react-bootstrap';

function MusicLinks({profile}) {
  return (    
    <Row className="justify-content-center">
      <Col className="col-lg-5 mtop">
        <ul className="list-group">
          {profile.music_links.map((link) => (
            <MusicLink link={link} key={link.id}/>        
          ))}
        </ul>
      </Col>
    </Row>
  );
}

export default MusicLinks;