import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer className="py-5">
        <div className="container">
          <p className="m-0 text-center text-white">
            Copyright &copy; <a href="http://thegreatfiltermusic.com">The Great Filter</a> 2022
          </p>
        </div>
      </footer>
      <br/><br/>
    </div>    
  );
}

export default Footer;