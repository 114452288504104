import React from "react";
import ReactGA from 'react-ga';

function PageTemplate({page}) {
  return (
    <div className={page.slug}>
      <div className="container">
          {ReactGA.pageview(window.location.pathname)}
          {page}
      </div>
    </div>
  );
}

export default PageTemplate;