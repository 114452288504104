import React from "react";
import MusicLinks from "./MusicLinks"

class Music extends React.Component {
  render() {      
    return (
      <>
        <MusicLinks profile={this.props.profile} />
      </>
    );
  }
}

export default Music;